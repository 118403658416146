import React from "react";
import Skeleton from 'react-loading-skeleton'
const LoadingSkeletonCategory = () => {

  return <div className="row">
    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-6">
      <div className="women-box">
        <figure className="categoryCard">
          <div className="mini-bx cp">
            <Skeleton height={100} count={2} />
          </div>
          <figcaption>
            <h2>
              <b> <Skeleton height={50} count={2} /></b>
            </h2>
          </figcaption>
        </figure>
      </div>
    </div>

    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-6">
      <div className="women-box">
        <figure className="categoryCard">
          <div className="mini-bx cp">
            <Skeleton height={100} count={2} />
          </div>
          <figcaption>
            <h2>
              <b> <Skeleton height={50} count={2} /></b>
            </h2>
          </figcaption>
        </figure>
      </div>
    </div>

    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-6">
      <div className="women-box">
        <figure className="categoryCard">
          <div className="mini-bx cp">
            <Skeleton height={100} count={2} />
          </div>
          <figcaption>
            <h2>
              <b> <Skeleton height={50} count={2} /></b>
            </h2>
          </figcaption>
        </figure>
      </div>
    </div>
  </div>
}
export default LoadingSkeletonCategory