import React from "react";
import Skeleton from 'react-loading-skeleton'
const LoadingSkeletonProduct = () => {
   
  return <> <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
          <div className="women-box">
            <figure>
              <a to='/'>
                <div className="mini-bx">
                  <Skeleton height={100} count={3}/>
                </div>
              </a>
              <figcaption>
                <h2>
                  <a to='/'>
                    <b className='link-prd'>
                      <Skeleton />
                    </b>
                  </a>
                </h2>

                <p className="m-0"><Skeleton /></p>
              </figcaption>
              <h5><Skeleton/></h5>
            </figure>
          </div>
        </div>
        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
        <div className="women-box">
          <figure>
            <a to='/'>
              <div className="mini-bx">
                <Skeleton height={100} count={3}/>
              </div>
            </a>
            <figcaption>
              <h2>
                <a to='/'>
                  <b className='link-prd'>
                    <Skeleton />
                  </b>
                </a>
              </h2>

              <p className="m-0"><Skeleton /></p>
            </figcaption>
            <h5><Skeleton/></h5>
          </figure>
        </div>
      </div>
      <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
      <div className="women-box">
        <figure>
          <a to='/'>
            <div className="mini-bx">
              <Skeleton height={100} count={3}/>
            </div>
          </a>
          <figcaption>
            <h2>
              <a to='/'>
                <b className='link-prd'>
                  <Skeleton />
                </b>
              </a>
            </h2>

            <p className="m-0"><Skeleton /></p>
          </figcaption>
          <h5><Skeleton/></h5>
        </figure>
      </div>
    </div>
    </>
}

export default LoadingSkeletonProduct