import React, { useEffect, useContext, useState } from "react";
import CategoryCard from "../../Components/CategoryCard";
import SearchBar from "../../Components/SearchBar";
import CategoriesListLoader from "../../Components/Loaders/CategoriesListLoader";
import { getCategories } from "../../config/services";
import OrgInfoContext from "../../Contexts/OrgInfo.context";
import InfiniteScroll from 'react-infinite-scroller';
import LoadingSkeletonCategory from "../../Components/LoadingSkeletonCategory";

const CategoriesList = () => {
    const { orgInfo } = useContext(OrgInfoContext);
    const [categoriesData, setCategoriesData] = useState([]);
    const [pageInfo, setPageInfo] = useState({});
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [currentSearchStr, setCurrentSearchStr] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const APIGetCategories = async () => {
        const getCategoriesData = await getCategories(pageInfo, orgInfo.store_url, 0, currentSearchStr);
        // console.log('[CategoriesList.js] getCategoriesData >', getCategoriesData);
        setIsLoading(false);
        if (getCategoriesData.status) {
            const existingData = [...categoriesData, ...getCategoriesData.data];
            setCategoriesData(existingData);
            setPageInfo(getCategoriesData.page);
            setIsLoadingMore(false);

        }

    };
    useEffect(() => {
        document.title = "Categories | " + document.title;
    }, []);

    useEffect(async () => {
        APIGetCategories();
    }, [currentSearchStr]);

    const loadFunc = () => {
        // console.log('[CategoriesList.js] loadFunc Called')
        if (!isLoadingMore && pageInfo?.next) {
            setIsLoadingMore(true);
            APIGetCategories();
        }
    };

    const searchHandle = (searchString = "") => {
        // console.log('[CategoriesList.js] searchHandle searchString >' + searchString)
        if (searchString !== currentSearchStr) {
            setPageInfo({});
            setCategoriesData([]);
            setCurrentSearchStr(searchString);
            setIsLoading(true);
            // APIGetCategories();
        }
    };

    return (
        <>
            {isLoading ? <CategoriesListLoader /> : null}
            {<SearchBar currentSearchStr={currentSearchStr} placeHolder={"Search For Categories"} searchFunctionName={"searchHandle"} searchHandle={searchHandle} />}
            { false && isLoading ? null :
                <section className="category-wrp product-listing-wrp search-wrapper">
                    <div className="container-fluid">
                        <h2>All Categories</h2>
                        { isLoading ? <LoadingSkeletonCategory /> : null}
                        {
                            !categoriesData.length && !isLoading ?
                                <div className="not-found-block">
                                    <div className="found-box">
                                        <img src="/images/category-not-found.svg" alt="" />
                                        <h4>No Category found</h4>
                                        <p>There is no category added yet</p>
                                    </div>
                                </div>
                                :
                                <InfiniteScroll
                                    pageStart={0}
                                    loadMore={loadFunc}
                                    hasMore={true}
                                    threshold={50}
                                    useWindow={true}
                                    isReverse={false}
                                // loader={<div className="loader" key={0}>Loading ...</div>}
                                >
                                    <div className="row">
                                        {categoriesData.map((cat, ind) => (
                                            <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-6" key={ind}>
                                                <div className="women-box">

                                                    <CategoryCard categoryInfo={cat} />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </InfiniteScroll>
                        }

                        {isLoadingMore ?
                            <div className="pro-lst-blks">
                                <div className="view-allpro">
                                    <a className="view-btn">Loading ...</a>
                                </div>
                            </div>
                            :
                            null
                        }
                        {
                            categoriesData.length ?
                                <>
                                    <br />
                                    <br />
                                </>
                                :
                                null
                        }

                    </div>
                </section>
            }
        </>
    );
};

export default CategoriesList;