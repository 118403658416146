import React, { useEffect, useState } from "react";
import SubCategoryCard from "../../Components/SubCategoryCard";

const SubCategoryListing = ({ /* setActiveCategoryId, activeCategoryId, */ pageInfo, handleShowMore, subCatData, selectCategoryHandle }) => {
    // console.log('[SubCategoryListing.js] pageInfo >', pageInfo)
    // console.log('[SubCategoryListing.js] subCatData >', subCatData);

    // Note : do not remove this, it is help full for show more category
    const [listingPageInfo, setListingPageInfo] = useState(pageInfo);
    useEffect(() => {
        // console.log('[SubCategoryListing.js] useEffect pageInfo >', pageInfo)
        setListingPageInfo(pageInfo);

        return () => { };
    }, [pageInfo]);
    // Note : do not remove this, it is help full for show more category Till here

    const cardOnClickHandler = (subCateInfo) => {
        // console.log('[SubCategoryListing.js] subCateInfo >', subCateInfo)
        if (subCateInfo?.title) {
            if (subCateInfo?.isLoadMore) {
                handleShowMore();
            } else {
                // setIsActiveCard(true)
                // setActiveCategoryId(0)
                selectCategoryHandle(0);
            }
        } else {
            // setIsActiveCard(true)
            // setActiveCategoryId(subCategoryData.category_id)
            selectCategoryHandle(subCateInfo.category_id);
        }
    };

    return (
        <section className="category-choice-wrp tp100">
            <div className="atc-box">
                <div className="container-fluid">
                    <hr />
                </div>
            </div>
            <div className="container-fluid">
                <div className="choice-cat-lst">
                    <ul className="pl-0">
                        {subCatData.map((sd, index) => (
                            // <div onClick={() => cardOnClickHandler(sd)} className="item" key={index} >
                            <SubCategoryCard key={index}  /* setActiveCategoryId={setActiveCategoryId} activeCategoryId={activeCategoryId} */ cardOnClickHandler={cardOnClickHandler} selectCategoryHandle={selectCategoryHandle} handleShowMore={handleShowMore} subCateInfo={sd} />
                            /* </div> */
                        ))}
                    </ul>
                </div>
            </div>
        </section>
    );
};

export default SubCategoryListing;