import React, { useContext, useState } from "react";
import { Modal, Button, ListGroup } from "react-bootstrap";
import { isTouchDevice } from "../../common";
import OrgInfoContext from "../../Contexts/OrgInfo.context";
import { useHistory, useLocation } from 'react-router-dom';
import { privateRoutes } from "../../config/constant";
import countrList from '../../config/country_code.json'
const LoginModel = (props) => {
    // Handle MobileNumber
    const { currentLoginFlowStatus, setCurrentLoginFlowStatus, orgInfo } = useContext(OrgInfoContext);
    let history = useHistory();
    const location = useLocation();
    const [countryCode, setCountryCode] = useState({"name": "India","dial_code": "+91","emoji": "🇮🇳","code": "IN"});
    const [isOpenCountrModal, setToggleCountryModal] = useState(false);
    const isPrivatePath = privateRoutes.includes(location.pathname.toString().split('/').pop());
    const defaultCountryCode = "IN";

    const handleMobileNumberSubmit = () => {
        if (/^\d{10}$/.test(props.mobile.toString())) {
            props.setMobileError('');
            props.handleMobileSubmit();

        } else {
            props.setMobileError('Please Provide Valid Mobile Number');
        }
    };

    const notEqualDefaultCode = countryCode.code != defaultCountryCode;
    const mobileData = (
        <div className="add-text-block mobile-verifybx">
            <img src="/images/mobile-img.svg" alt="" />
            <h4>Mobile Verification</h4>
            <p>Please enter your 10 digit mobile number.</p>
            <div className="mobile-num-bx">
                <div>
                    <div className="d-flex">
                        <div 
                            className="align-items-center border-0 d-flex form-select mr-5" 
                            onClick={()=> setToggleCountryModal(true)}
                            style={{width: '30%'}}
                        >
                            <span>
                                {countryCode.emoji}
                            </span> 
                            <span>
                                {countryCode.dial_code}
                            </span>
                        </div>
                        <input
                            type="number"
                            name=""
                            placeholder="Mobile Number"
                            className="form-control rmArrow"
                            value={props.mobile}
                            onChange={(event) => {
                                props.setMobileError("");
                                props.setMobile(event.target.value);
                            }}
                        />
                    </div>
                    <h6 className="error-msg">
                        {props.mobileError}
                    </h6>
                    <div className="btn-group w-100">
                        <button type="button" onClick={() => {
                            setCurrentLoginFlowStatus(0);
                            if (orgInfo.is_public && isPrivatePath) {
                                // clearAllBodyScrollLocks()
                                history.push('/' + orgInfo.store_url);
                            }

                        }} className="btn-otp btn-otp-cancel">CANCEL</button>
                        <button className="btn-otp" onClick={handleMobileNumberSubmit}>{notEqualDefaultCode ? 'GET WHATSAPP OTP' : 'SEND OTP'} </button>
                    </div>
                </div>
            </div>
        </div>
    );

    // // console.log('is Touch device', );
    const onSelectCountryCode = (c) => {
        setCountryCode(c); 
        setToggleCountryModal(false);

        if(c.code == defaultCountryCode) {
            props.setIsSendToWhatsApp(false);
        } else {
            props.setCountryCode(c.dial_code);
            props.setIsSendToWhatsApp(true);
        }
    }

    const countrySelectionModal = (
        <Modal show={isOpenCountrModal} onHide={()=> setToggleCountryModal(false)}>
            <Modal.Header>
                <Modal.Title>Select a country</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{height: '300px',overflowY: 'scroll'}}>
                <ListGroup as="ul">
                    {countrList.map( (c, index) => 
                        <ListGroup.Item key={index} as="li" active={countryCode.dial_code === c.dial_code} onClick={() => onSelectCountryCode(c)}> 
                            <div className="d-flex justify-content-between">
                                <span>{c.emoji} {c.name} {c.code}</span> 
                                <span>{c.dial_code}</span>
                            </div>
                        </ListGroup.Item>
                    )}
                </ListGroup>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={()=> setToggleCountryModal(false)}>Close</Button>
            </Modal.Footer>
        </Modal>
      )
    return (
        <>
            {
                isTouchDevice() ?
                    <>
                        <div className={((currentLoginFlowStatus == 1) ? "mobile-menu-open" : "") + " back-blur-fx add-clbx"}></div>
                        <div className={((currentLoginFlowStatus == 1) ? "mobile-menu-open" : "") + " modal-content add-clbx mobilecart-aside"}>
                            {mobileData}
                        </div>
                    </>
                    :
                    < Modal className="background-blurbx" onHide={() => { }} show={currentLoginFlowStatus == 1} >
                        {mobileData}
                    </Modal >
            }
            {countrySelectionModal}

        </>
    );
};

export default LoginModel;