import React from "react";
import "./custom.scss";
import "./loader.js";
import { Switch } from 'react-router-dom';
import Routes from "./Routes";
import MainContext, { MainContextProvider } from "./Contexts/Main.context";
import 'react-loading-skeleton/dist/skeleton.css'
const App = () => {

  return (
    <MainContextProvider>
      <MainContext.Consumer>
        {(context) => (
          <Switch>
            <Routes />
          </Switch>
        )}
      </MainContext.Consumer>
    </MainContextProvider>
  );
}

export default App;