import React from 'react';
import Header from "../Components/Header";
import StickeyMenu from "../Components/StickeyMenu";
import { useParams, withRouter, useLocation } from "react-router-dom";
import OrgInfoContext, { OrgInfoContextProvider } from '../Contexts/OrgInfo.context';
import AuthFlow from './Auth/AuthFlow';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { privateRoutes } from '../config/constant';
import ProductQtyModal from '../Components/ProductQtyModal';
import ProductQtyModalNew from '../Components/ProductQtyModalNew';
import MainLoader from '../Components/MainLoader';

// import { getOrgInfo } from '../config/services';

const AppLayout = (props) => {
    const { storeName } = useParams();
    // console.log('[AppLayout.js] storeName > ' + storeName);
    const location = useLocation();
    // console.log('[AppLayout.js] location.pathname > ' + location.pathname);
    const isPrivatePath = privateRoutes.includes(location.pathname.toString().split('/').pop());
    // console.log('[AppLayout.js] isPrivatePath > ' + isPrivatePath);
    // useEffect(async () => {
    //     const orgInfoRes = await getOrgInfo(storeName);
    //     // // Check if Org Details Not Found Then Redirect To 404 Page
    //     if(!orgInfoRes.status){

    //     }
    //     // console.log('orgInfoRes >>>  ' + JSON.stringify(orgInfoRes))
    // }, []);
    return (
        <>
            <OrgInfoContextProvider>
                <OrgInfoContext.Consumer>

                    {(context) => {
                        return (
                            <>
                                {(!isPrivatePath || context.customerInfo) && context.fullBgBlur && <div className="background-blurbx-force"></div>}
                                {(!isPrivatePath || context.customerInfo) && (context.orgInfo?.is_public || context.customerInfo) ? (<Header withBackButton={props.withoutSticky} />) : null}
                                {(!isPrivatePath || context.customerInfo) && (context.orgInfo?.is_public || context.customerInfo) ? props.children : null}
                                {(!isPrivatePath || context.customerInfo) && (context.orgInfo?.is_public || context.customerInfo) ? (!props.withoutSticky && <StickeyMenu />) : null}
                                {!context.customerInfo && <AuthFlow {...props} />}
                                {(context.customerInfo && context.productQtyModelDesId) ? < ProductQtyModalNew /> : null}
                                <ToastContainer />
                               { !context || context.isMainLoading ? <MainLoader /> : null}
                            </>
                        );
                    }}
                </OrgInfoContext.Consumer>
            </OrgInfoContextProvider>
        </>
    );
};

export default withRouter(AppLayout);